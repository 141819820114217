import React from 'react';
import PropTypes from 'prop-types';
import logoImg from '../assets/logos/logo.png';
import hmLogoImg from '../assets/logos/hm_logo.png';
// import logoImgUbt from '../assets/logos/ubt_logo.png';

const Logo = ({ width, height, isMain = false }) => {
	const { hostname } = window.location;
	if (hostname.includes('swastha-preprod')) {
		const h = isMain ? height / 7 : height;
		const w = isMain ? width / 3 : width / 44;
		return <img src={hmLogoImg} className='img-fluid' alt='logo' height={h} width={w} />;
	}
	return <img src={logoImg} className='img-fluid' alt='logo' height={height} width={width} />;
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	isMain: PropTypes.bool,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
	isMain: false,
};

export default Logo;
