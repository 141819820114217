import React, { useContext, useLayoutEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useTour } from '@reactour/tour';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/bootstrap/Button';
import { HeaderRight } from '../../../layout/Header/Header';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../components/bootstrap/OffCanvas';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import Alert from '../../../components/bootstrap/Alert';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import Icon from '../../../components/icon/Icon';
import ThemeContext from '../../../contexts/themeContext';
import LANG, { getLangWithKey } from '../../../lang';
import showNotification from '../../../components/extras/showNotification';
import useDarkMode from '../../../hooks/useDarkMode';
import Popovers from '../../../components/bootstrap/Popovers';
import Spinner from '../../../components/bootstrap/Spinner';
import AuthContext from '../../../contexts/authContext';

// eslint-disable-next-line react/prop-types
const CommonHeaderRight = ({ beforeChildren, afterChildren }) => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const { loggoff } = useContext(AuthContext);
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/auth-pages/login'), [navigate]);

	const [offcanvasStatus, setOffcanvasStatus] = useState(false);
	const [shouldLoggoff, setShouldLoggoff] = useState(false);

	const { i18n } = useTranslation();

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		showNotification(
			<span className='d-flex align-items-center'>
				<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
				<span>{`Language changed to ${getLangWithKey(lng)?.text}`}</span>
			</span>,
			'You updated the language of the site. (Only "Aside" was prepared as an example.)',
		);
	};

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language);
	});

	const { setIsOpen } = useTour();

	return (
		<>
			<HeaderRight>
				<div className='row g-3'>
					{beforeChildren}
					{/* Tour Modal */}
					{localStorage.getItem('tourModalStarted') === 'shown' && false && (
						<div className='col-auto position-relative'>
							<Popovers trigger='hover' desc='Start the "Facit" tour'>
								<Button
									{...styledBtn}
									icon='Tour'
									onClick={() => setIsOpen(true)}
									aria-label='Start the "Facit" tour'
								/>
							</Popovers>
							<Icon
								icon='Circle'
								className={classNames(
									'position-absolute start-65',
									'text-danger',
									'animate__animated animate__heartBeat animate__infinite animate__slower',
								)}
							/>
						</div>
					)}

					{/* Dark Mode */}
					<div className='col-auto'>
						<Popovers trigger='hover' desc='Dark / Light mode'>
							<Button
								{...styledBtn}
								onClick={() => setDarkModeStatus(!darkModeStatus)}
								className='btn-only-icon'
								data-tour='dark-mode'>
								<Icon
									icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
									color={darkModeStatus ? 'info' : 'warning'}
									className='btn-icon'
								/>
							</Button>
						</Popovers>
					</div>

					{/*	Full Screen */}
					<div className='col-auto'>
						<Popovers trigger='hover' desc='Fullscreen'>
							<Button
								{...styledBtn}
								icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
								onClick={() => setFullScreenStatus(!fullScreenStatus)}
								aria-label='Toggle dark mode'
							/>
						</Popovers>
					</div>

					{/* Lang Selector */}
					<div className='col-auto'>
						<Dropdown>
							<DropdownToggle hasIcon={false}>
								{typeof getLangWithKey(i18n.language)?.icon === 'undefined' ? (
									<Button
										{...styledBtn}
										className='btn-only-icon'
										aria-label='Change language'
										data-tour='lang-selector'>
										<Spinner isSmall inButton='onlyIcon' isGrow />
									</Button>
								) : (
									<Button
										{...styledBtn}
										icon={getLangWithKey(i18n.language)?.icon}
										aria-label='Change language'
										data-tour='lang-selector'
									/>
								)}
							</DropdownToggle>
							<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
								{Object.keys(LANG).map((i) => (
									<DropdownItem key={LANG[i].lng}>
										<Button
											icon={LANG[i].icon}
											onClick={() => changeLanguage(LANG[i].lng)}>
											{LANG[i].text}
										</Button>
									</DropdownItem>
								))}
							</DropdownMenu>
						</Dropdown>
					</div>

					{/* Quick Panel */}
					<div className='col-auto'>
						<Dropdown>
							<DropdownToggle hasIcon={false}>
								<Button {...styledBtn} icon='Tune' aria-label='Quick menu' />
							</DropdownToggle>
							<DropdownMenu isAlignmentEnd size='lg' className='py-0 overflow-hidden'>
								<div className='row g-0'>
									<div
										className={classNames(
											'col-12',
											'p-4',
											'd-flex justify-content-center',
											'fw-bold fs-5',
											'text-info',
											'border-bottom border-info',
											{
												'bg-l25-info': !darkModeStatus,
												'bg-lo25-info': darkModeStatus,
											},
										)}>
										Quick Panel
									</div>
									<div
										className={classNames(
											'col-6 p-4 transition-base cursor-pointer bg-light-hover',
											'border-end border-bottom',
											{ 'border-dark': darkModeStatus },
										)}>
										<div className='d-flex flex-column align-items-center justify-content-center'>
											<Icon icon='Public' size='3x' color='info' />
											<span>Dealers</span>
											<small className='text-muted'>Options</small>
										</div>
									</div>
									<div
										className={classNames(
											'col-6 p-4 transition-base cursor-pointer bg-light-hover',
											'border-bottom',
											{ 'border-dark': darkModeStatus },
										)}>
										<div className='d-flex flex-column align-items-center justify-content-center'>
											<Icon icon='Upcoming' size='3x' color='success' />
											<span>Inbox</span>
											<small className='text-muted'>Configuration</small>
										</div>
									</div>
									<div
										className={classNames(
											'col-6 p-4 transition-base cursor-pointer bg-light-hover',
											'border-end',
											{ 'border-dark': darkModeStatus },
										)}>
										<div className='d-flex flex-column align-items-center justify-content-center'>
											<Icon icon='Print' size='3x' color='danger' />
											<span>Print</span>
											<small className='text-muted'>Settings</small>
										</div>
									</div>
									<div className='col-6 p-4 transition-base cursor-pointer bg-light-hover'>
										<div className='d-flex flex-column align-items-center justify-content-center'>
											<Icon
												icon='ElectricalServices'
												size='3x'
												color='warning'
											/>
											<span>Power</span>
											<small className='text-muted'>Mode</small>
										</div>
									</div>
								</div>
							</DropdownMenu>
						</Dropdown>
					</div>

					{/*	Notifications */}
					{false && (
						<div className='col-auto'>
							<Button
								{...styledBtn}
								icon='Notifications'
								onClick={() => setOffcanvasStatus(true)}
								aria-label='Notifications'
							/>
						</div>
					)}

					{/*	Logout */}
					<div className='col-auto'>
						<Popovers trigger='hover' desc='Log Off'>
							<Button
								{...styledBtn}
								icon='PowerSettingsNew'
								onClick={() => setShouldLoggoff(true)}
								aria-label='Notifications'
							/>
						</Popovers>
					</div>
					{afterChildren}
				</div>

				<OffCanvas
					id='notificationCanvas'
					titleId='offcanvasExampleLabel'
					placement='end'
					isOpen={offcanvasStatus}
					setOpen={setOffcanvasStatus}>
					<OffCanvasHeader setOpen={setOffcanvasStatus}>
						<OffCanvasTitle id='offcanvasExampleLabel'>Notifications</OffCanvasTitle>
					</OffCanvasHeader>
					<OffCanvasBody>
						<Alert icon='ViewInAr' isLight color='info' className='flex-nowrap'>
							4 new components added.
						</Alert>
						<Alert icon='ThumbUp' isLight color='warning' className='flex-nowrap'>
							New products added to stock.
						</Alert>
						<Alert icon='Inventory2' isLight color='danger' className='flex-nowrap'>
							There are products that need to be packaged.
						</Alert>
						<Alert icon='BakeryDining' isLight color='success' className='flex-nowrap'>
							Your food order is waiting for you at the consultation.
						</Alert>
						<Alert icon='Escalator' isLight color='primary' className='flex-nowrap'>
							Escalator will turn off at 6:00 pm.
						</Alert>
					</OffCanvasBody>
				</OffCanvas>
			</HeaderRight>

			<Modal setIsOpen={setShouldLoggoff} isOpen={shouldLoggoff} isStaticBackdrop>
				<ModalHeader setIsOpen={setShouldLoggoff}>
					<ModalTitle id='confirm-logout'>Please Confirm</ModalTitle>
				</ModalHeader>
				<ModalBody>Are you sure you want to Logout?</ModalBody>
				<ModalFooter>
					<Button
						color='info'
						isOutline
						className='border-0'
						onClick={() => setShouldLoggoff(false)}>
						Close
					</Button>
					<Button
						color='info'
						icon='PowerSettingsNew'
						onClick={() => loggoff(handleOnClick)}>
						Logout
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default CommonHeaderRight;
