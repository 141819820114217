import React from 'react';
import { staticPages, mainSection, authentication } from '../menu';
import DashboardHeader from '../pages/common/Headers/DashboardHeader';
import DefaultHeader from '../pages/common/Headers/DefaultHeader';

const headers = [
	{ path: authentication.login.path, element: null, exact: true },
	{ path: authentication.signUp.path, element: null, exact: true },
	{ path: staticPages.page404.path, element: null, exact: true },
	{ path: mainSection.dashboard.path, element: <DashboardHeader />, exact: true },
	{
		path: `*`,
		element: <DefaultHeader />,
	},
];

export default headers;
