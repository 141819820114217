import React from 'react';
// import ReactDOM from 'react-dom'; // For React 17
import { createRoot } from 'react-dom/client'; // For React 18
import { HashRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import { AuthContextProvider } from './contexts/authContext';
import './i18n';

function updateFavicon() {
	const { hostname } = window.location;
	let faviconPath = '/favicon-default.ico';

	if (hostname.includes('swastha-preprod')) {
		faviconPath = '/hm_favicon.ico';
	} else {
		faviconPath = '/favicon.ico';
	}

	const link = document.getElementById('dynamic-favicon');
	if (link) {
		link.href = process.env.PUBLIC_URL + faviconPath;
	}
}

const children = (
	<Router>
		<React.StrictMode>
			<ThemeContextProvider>
				<AuthContextProvider>
					<App />
				</AuthContextProvider>
			</ThemeContextProvider>
		</React.StrictMode>
	</Router>
);

const container = document.getElementById('root');
updateFavicon();

// ReactDOM.render(children, container); // For React 17
createRoot(container).render(children); // For React 18

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
