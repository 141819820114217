import React, { useContext, useRef } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
// import { useTranslation } from 'react-i18next';
import Brand from '../Brand/Brand';
import Navigation, { NavigationLine } from '../Navigation/Navigation';
import User from '../User/User';
import {
	mainSection,
	operations,
	userManagement,
	masterDataManagement,
	customerManagement,
	tenantManagement,
	iotDemo,
	reports,
} from '../../menu';
import ThemeContext from '../../contexts/themeContext';
import Tooltips from '../../components/bootstrap/Tooltips';
import useAsideTouch from '../../hooks/useAsideTouch';
import AuthContext from '../../contexts/authContext';
import { roles, service } from '../../defaults';

const Aside = () => {
	const { hostname } = window.location;
	const { getCurrentUser } = useContext(AuthContext);
	const tenantId = getCurrentUser()?.tenantId;
	const userRoles = (getCurrentUser()?.roles || []).map((i) => i.toUpperCase());
	const isSuperAdmin = userRoles.includes(roles.SUPER_ADMIN);
	const serviceType = getCurrentUser()?.isMrf ? service.MRF : service.COLLECTION;

	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } = useAsideTouch();
	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';
	const constraintsRef = useRef(null);
	const showTenantConfigSection = tenantId && tenantId === 'swastha_admin';

	const motionClassName = classNames(
		'aside',
		{ open: asideStatus },
		{
			'aside-touch-bar': hasTouchButton && isModernDesign,
			'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
			'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
		},
	);

	const filterByRole = (items) => {
		const roleBasedMenu = Object.entries(items).reduce((menuItem, [key, value]) => {
			const hasServicePermission = value.service.some((role) => serviceType === role);
			const hasAllowedRole = value.role.some((role) => userRoles.includes(role));
			const hasPermission = hasServicePermission && (hasAllowedRole || isSuperAdmin);
			return hasPermission ? { ...menuItem, [key]: value } : menuItem;
		}, {});

		return { menu: roleBasedMenu, isEmpty: !Object.keys(roleBasedMenu).length };
	};

	const mainSectionArea = filterByRole(mainSection);
	const operationsArea = filterByRole(operations);
	const userArea = filterByRole(userManagement);
	const masterDataArea = filterByRole(masterDataManagement);
	const customerArea = filterByRole(customerManagement);
	const tenantActionArea = filterByRole(tenantManagement);
	const iotdemoActionArea = filterByRole(iotDemo);
	const reportArea = filterByRole(reports);

	let headClass = 'aside-head';
	if (hostname.includes('swastha-preprod')) {
		headClass = 'aside-hm-head';
	}

	return (
		<>
			<motion.aside style={asideStyle} className={motionClassName}>
				<div className={headClass}>
					<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
				</div>
				<div className='aside-body'>
					<Navigation menu={mainSectionArea.menu} id='aside-dashboard' />
					{!mainSectionArea.isEmpty && <NavigationLine />}

					<Navigation menu={customerArea.menu} id='aside-menu' />
					{!customerArea.isEmpty && <NavigationLine />}

					<Navigation menu={operationsArea.menu} id='aside-menu' />
					{!operationsArea.isEmpty && <NavigationLine />}

					<Navigation menu={reportArea.menu} id='aside-menu' />
					{!reportArea.isEmpty && <NavigationLine />}

					<Navigation menu={userArea.menu} id='aside-menu' />
					{!userArea.isEmpty && <NavigationLine />}

					<Navigation menu={masterDataArea.menu} id='aside-menu' />
					{!masterDataArea.isEmpty && <NavigationLine />}

					{showTenantConfigSection && (
						<>
							<Navigation menu={tenantActionArea.menu} id='aside-menu' />
							{!tenantActionArea.isEmpty && <NavigationLine />}
							<Navigation menu={iotdemoActionArea.menu} id='aside-menu' />
						</>
					)}
				</div>
				<div className='aside-foot'>
					<User />
				</div>
			</motion.aside>
			{asideStatus && hasTouchButton && isModernDesign && (
				<>
					<motion.div className='aside-drag-area' ref={constraintsRef} />
					<Tooltips title='Toggle Aside' flip={['top', 'right']}>
						<motion.div
							className='aside-touch'
							drag='x'
							whileDrag={{ scale: 1.2 }}
							whileHover={{ scale: 1.1 }}
							dragConstraints={constraintsRef}
							// onDrag={(event, info) => console.log(info.point.x, info.point.y)}
							dragElastic={0.1}
							style={{ x, zIndex: 1039 }}
							onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}
						/>
					</Tooltips>
				</>
			)}
		</>
	);
};

export default Aside;
