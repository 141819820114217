import React, { useLayoutEffect, forwardRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../contexts/authContext';
import { authentication } from '../../menu';

const PageWrapper = forwardRef(({ isProtected, title, description, className, children }, ref) => {
	const { isUserAuthernticated } = useContext(AuthContext);
	useLayoutEffect(() => {
		const { REACT_APP_SITE_NAME: app, REACT_APP_META_DESC: appDesc } = process.env;
		const desc = description || appDesc;
		document.getElementsByTagName('TITLE')[0].text = `${title ? `${title} | ` : ''}${app}`;
		document.querySelector('meta[name="description"]').setAttribute('content', desc);
	});

	const navigate = useNavigate();
	useEffect(() => {
		if (isProtected && !isUserAuthernticated()) {
			navigate(`../${authentication.login.path}`);
		}
		return () => {};
	}, []);

	return (
		<div ref={ref} className={classNames('page-wrapper', 'container-fluid', className)}>
			{children}
		</div>
	);
});

PageWrapper.propTypes = {
	isProtected: PropTypes.bool,
	title: PropTypes.string,
	description: PropTypes.string,
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};

PageWrapper.defaultProps = {
	isProtected: true,
	title: null,
	description: null,
	className: null,
};

export default PageWrapper;
