import React, { useCallback, useContext, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormik } from 'formik';
import Toasts from '../../../components/bootstrap/Toasts';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import Spinner from '../../../components/bootstrap/Spinner';
import useApi from '../../../hooks/useApi';
import { endpoints } from '../../../defaults';

// eslint-disable-next-line react/prop-types
const LoginHeader = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

const Login = ({ isSignUp }) => {
	const { darkModeStatus } = useDarkMode();
	const { setCurrentUser } = useContext(AuthContext);
	const { addToast } = useToasts();

	const [usernameInput, setUsernameInput] = useState(false);
	const [isNewUser, setIsNewUser] = useState(isSignUp);
	const [loading, setLoading] = useState(false);
	const { request: login } = useApi('post');

	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/'), [navigate]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: { tenantId: '', loginUsername: '', loginPassword: '' },
		validate: (values) => {
			const errors = {};

			if (!values.tenantId) {
				errors.tenantId = 'Required';
			}

			if (!values.loginUsername) {
				errors.loginUsername = 'Required';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Required';
			}

			return errors;
		},
		onSubmit: async (values) => {
			if (values.tenantId && values.loginUsername && values.loginPassword) {
				const payload = {
					tenantId: values.tenantId,
					mobileNumber: values.loginUsername,
					password: values.loginPassword,
				};
				setLoading(true);
				const apiResponse = await login(endpoints.AUTHENTICATE, payload);
				const { response, error } = apiResponse;
				if (!error && response.data) {
					const { data: apiData } = response;
					setCurrentUser(apiData);
					handleOnClick();
				} else {
					const { response: errRes } = error;
					addToast(
						<Toasts title='Error' icon='Cancel' iconColor='danger' time='Now'>
							{errRes?.data?.message || 'Error Occured. Please contact Admin !!'}
						</Toasts>,
						{
							appearance: 'error',
							autoDismiss: true,
						},
					);
				}
				setLoading(false);
			}
		},
	});

	const renderSignupForm = () => {
		return (
			<>
				<div className='col-12'>
					<FormGroup id='signup-email' isFloating label='Your email'>
						<Input type='email' autoComplete='email' />
					</FormGroup>
				</div>
				<div className='col-12'>
					<FormGroup id='signup-name' isFloating label='Your name'>
						<Input autoComplete='given-name' />
					</FormGroup>
				</div>
				<div className='col-12'>
					<FormGroup id='signup-surname' isFloating label='Your surname'>
						<Input autoComplete='family-name' />
					</FormGroup>
				</div>
				<div className='col-12'>
					<FormGroup id='signup-password' isFloating label='Password'>
						<Input type='password' autoComplete='password' />
					</FormGroup>
				</div>
				<div className='col-12'>
					<Button color='info' className='w-100 py-3' onClick={handleOnClick}>
						Sign Up
					</Button>
				</div>
			</>
		);
	};

	const isLoginDisabled = () => {
		const { tenantId, loginUsername, loginPassword } = formik.values;
		return !tenantId || !loginUsername || !loginPassword;
	};

	const formProp = {
		tenantId: { isFloating: true, label: 'Tenant ID', style: { margin: '10px 0' } },
		userName: {
			isFloating: true,
			label: 'Your username or mobile number',
			style: { margin: '10px 0' },
		},
		password: { isFloating: true, label: 'Password', style: { margin: '10px 0' } },
	};

	const renderLoginForm = () => {
		return (
			<>
				<div className='col-12'>
					<FormGroup id='tenantId' {...formProp.tenantId}>
						<Input
							my={4}
							autoComplete='username'
							value={formik.values.tenantId}
							isTouched={formik.touched.tenantId}
							invalidFeedback={formik.errors.tenantId}
							validFeedback='Looks good!'
							isValid={formik.isValid}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
					</FormGroup>
					<FormGroup id='loginUsername' {...formProp.userName}>
						<Input
							my={4}
							autoComplete='username'
							value={formik.values.loginUsername}
							isTouched={formik.touched.loginUsername}
							invalidFeedback={formik.errors.loginUsername}
							validFeedback='Looks good!'
							isValid={formik.isValid}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
					</FormGroup>
					<FormGroup id='loginPassword' {...formProp.password}>
						<Input
							my={4}
							type='password'
							autoComplete='password'
							value={formik.values.loginPassword}
							isTouched={formik.touched.loginPassword}
							invalidFeedback={formik.errors.loginPassword}
							validFeedback='Looks good!'
							isValid={formik.isValid}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
						/>
					</FormGroup>
				</div>
				<div className='col-12'>
					<div className='col-12 mt-3'>
						<Button
							color='warning'
							className='w-100 py-3'
							isDisable={isLoginDisabled()}
							onClick={formik.handleSubmit}>
							{loading && <Spinner isSmall inButton isGrow />}
							Login
						</Button>
					</div>
				</div>
			</>
		);
	};

	const renderSocialLogin = () => {
		return (
			<>
				<div className='col-12 mt-3 text-center text-muted'>OR</div>
				<div className='col-12 mt-3'>
					<Button
						isOutline
						color={darkModeStatus ? 'light' : 'dark'}
						className={classNames('w-100 py-3', {
							'border-light': !darkModeStatus,
							'border-dark': darkModeStatus,
						})}
						icon='CustomApple'>
						Sign in with Apple
					</Button>
				</div>
				<div className='col-12'>
					<Button
						isOutline
						color={darkModeStatus ? 'light' : 'dark'}
						className={classNames('w-100 py-3', {
							'border-light': !darkModeStatus,
							'border-dark': darkModeStatus,
						})}
						icon='CustomGoogle'>
						Continue with Google
					</Button>
				</div>
			</>
		);
	};

	return (
		<PageWrapper
			isProtected={false}
			title={isNewUser ? 'Sign Up' : 'Login'}
			className={classNames({ 'bg-warning': !isNewUser, 'bg-info': !!isNewUser })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<Logo width={400} isMain />
									</Link>
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-lo10-dark': darkModeStatus,
									})}>
									<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!!isNewUser}
												className='rounded-1 w-100'
												size='lg'
												// onClick={() => {
												// 	setUsernameInput(false);
												// 	setIsNewUser(!isNewUser);
												// }}
											>
												Login
											</Button>
										</div>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!isNewUser}
												className='rounded-1 w-100'
												size='lg'
												isDisable
												onClick={() => {
													setUsernameInput(false);
													setIsNewUser(!isNewUser);
												}}>
												Sign Up
											</Button>
										</div>
									</div>
								</div>

								<LoginHeader isNewUser={isNewUser} />

								<form className='row g-4'>
									{isNewUser ? renderSignupForm() : renderLoginForm()}
									{!usernameInput && renderSocialLogin()}
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
