import React, { useContext } from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import CommonHeaderRight from './CommonHeaderRight';
import Avatar from '../../../components/Avatar';
import USERS from '../../../common/data/userDummyData';
import AuthContext from '../../../contexts/authContext';

const DefaultHeader = () => {
	const { getCurrentUser } = useContext(AuthContext);
	const user = getCurrentUser();
	const { name, loginId, tenantName } = user || {};

	return (
		<Header>
			<HeaderLeft>
				<div className='col d-flex align-items-center'>
					<div className='me-3'>
						<Avatar
							src={USERS.JOHN.src}
							srcSet={USERS.JOHN.srcSet}
							size={48}
							color='primary'
						/>
					</div>
					<div>
						<div className='fw-bold fs-6 mb-0'>
							{name || '-'}
							{!!tenantName && ` : ${tenantName}`}
						</div>
						<div className='text-muted'>
							<small>{loginId || ''}</small>
						</div>
					</div>
				</div>
			</HeaderLeft>
			<CommonHeaderRight />
		</Header>
	);
};

export default DefaultHeader;
