import axios from 'axios';

const baseInstance = () => {
	const API_URL_DEV = process.env.REACT_APP_API_URL_DEV;
	const headers = {
		'Accept-Version': 1,
		Accept: 'application/json',
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'application/json; charset=utf-8',
	};
	return axios.create({
		baseURL: [API_URL_DEV],
		timeout: 25000,
		headers,
	});
};

const authInstance = () => {
	const API_URL_DEV = process.env.REACT_APP_API_URL_DEV;
	const authToken = localStorage.getItem('token');
	let headers = {
		'Accept-Version': 1,
		Accept: 'application/json',
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'application/json; charset=utf-8',
	};
	if (authToken) {
		headers = { ...headers, Authorization: `Bearer ${authToken}` };
	}
	return axios.create({
		baseURL: [API_URL_DEV],
		timeout: 25000,
		headers,
	});
};

export default { baseInstance, authInstance };
